import React from 'react'
import { COLOR_CONSTANTS, colors } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import styled from 'styled-components'
import { H1, H2, Text } from '../components/Typography'
import { Box, Flex } from '../components/Layout'
import BlockOfTextWithTableOfContents from '../components/BlockOfTextWithTableOfContents'
import Footer from '../components/Footer'

const StyledText = styled(Text)`
  color: ${colors.primaryText};
  line-height: 25px;
`

const ListItem = styled.li`
  color: ${colors.primaryText};
  line-height: 25px;
  font-size: 14px;
  list-style: none;
`

const StyledH2 = styled(H2)`
  color: ${colors.primaryText};
  line-height: 25px;
  font-size: 20px;
`

const TOS = () => (
  <App>
    <SEO
      title="Vista Social Developer Terms"
      path="/api-terms-of-service/"
      description="Learn more about the terms of service for Vista Social, a social media management tool for business."
    />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
        Vista Social Developer Terms
      </H1>
      <Text mt="m" fontSize="xs" color="secondaryText">
        Effective March 27th, 2025
      </Text>
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px" mb="xl">
      <StyledText>
        These Vista Social Developer Terms ("Terms") form a binding agreement between you, on behalf of the legal entity
        you represent, and Vista Social, Inc. ("Vista Social," "we," "our," or "us"). If you're using Vista Social
        Services and APIs on behalf of a company, organization, or another entity, then "you" refers to that entity, and
        you’re confirming that you have the authority to agree to these Terms on its behalf. These Terms outline how you
        may access and use the Vista Social Services and APIs (as defined below). By developing on the Vista Social
        Services and APIs, you agree to comply with these Terms—so please review them carefully.
      </StyledText>

      <StyledText mt="m">
        BY ACCEPTING THESE TERMS, OR BY ACCESSING OR USING THE VISTA SOCIAL SERVICES AND APIS, YOU (A) CONFIRM THAT YOU
        HAVE READ AND UNDERSTAND THESE TERMS; (B) DECLARE THAT YOU HAVE THE LEGAL RIGHT, AUTHORITY, AND POWER TO ENTER
        INTO THIS AGREEMENT; AND (C) AGREE TO BE LEGALLY BOUND BY THESE TERMS. IF YOU DO NOT HAVE THE AUTHORITY TO AGREE
        TO THESE TERMS, OR IF YOU DISAGREE WITH ANY PART OF THEM, YOU MAY NOT ACCESS OR USE THE VISTA SOCIAL SERVICES OR
        APIS. Definitions
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        1. Definitions
      </H2>

      <ul>
        <ListItem>
          a. <strong>"APIs"</strong> refers to the Vista Social application programming interfaces, API-enabled
          accounts, software development kits, software, and other related features or tools (including API
          Documentation) that Vista Social provides to you, and may update from time to time.
        </ListItem>
        <ListItem>
          b. <strong>"API Documentation"</strong> means the documentation, data, and guidance about using the APIs that
          Vista Social makes available from time to time.
        </ListItem>
        <ListItem>
          c. <strong>"API Key"</strong> refers to the security key provided by Vista Social that enables you to access
          the APIs.
        </ListItem>
        <ListItem>
          d. <strong>"Beta Features"</strong> means any API features or versions labeled by Vista Social as beta or
          pilot offerings.
        </ListItem>
        <ListItem>
          e. <strong>"Data"</strong> includes any information, content, or data you or Your Application access, collect,
          or receive through the use of the APIs, including data related to or generated by Vista Social Users within
          the Vista Social Services, as well as data obtained by Vista Social from Third-Party Services on their behalf.
        </ListItem>
        <ListItem>
          f. <strong>"End User"</strong> means a Vista Social User who uses Your Application.
        </ListItem>
        <ListItem>
          g. <strong>"Malicious Software"</strong> refers to any harmful or malicious code, files, scripts, viruses,
          worms, Trojan horses, agents, or similar software.
        </ListItem>
        <ListItem>
          h. <strong>"Privacy Policy"</strong> refers to Vista Social's Privacy Policy available at
          https://vistasocial.com/privacy.
        </ListItem>
        <ListItem>
          i. <strong>"Vista Social Services"</strong> means the products and services that Vista Social provides.
        </ListItem>
        <ListItem>
          j. <strong>"Vista Social Terms of Service"</strong> refers to the Vista Social Terms of Service located at
          https://vistasocial.com/terms, or any other relevant customer agreements that govern use of Vista Social
          Services.
        </ListItem>
        <ListItem>
          k. <strong>"Vista Social User"</strong> means any user of the Vista Social Services.
        </ListItem>
        <ListItem>
          l. <strong>"Third-Party Services"</strong> includes any external software, services, or products used
          alongside Vista Social Services and APIs, and governed by their own terms—such as social media platforms or
          integration partners listed at www.vistasocial.com/integrations.
        </ListItem>
        <ListItem>
          m. <strong>"Your Application"</strong> refers to any apps, products, websites, or services you develop to work
          with the APIs and Vista Social Services.
        </ListItem>
        <ListItem>
          n. <strong>"Your User"</strong> means any authorized person you engage to use the APIs and Vista Social
          Services on your behalf.
        </ListItem>
      </ul>

      <Text>
        Other capitalized terms not defined in this Section will have the meaning given to them elsewhere in these
        Terms.
      </Text>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        2. Access Rights
      </H2>

      <StyledText mt="m">
        As long as you comply with these Terms, we grant you a limited, revocable, non-exclusive, non-transferable, and
        non-sublicensable right—during the Term—to access and use the Vista Social Services and APIs. This access is
        only for your internal business use in developing, testing, and supporting Your Application that interacts with
        the Vista Social Services, and only as allowed under these Terms and the applicable API Documentation. You
        acknowledge that no rights or licenses are granted to you beyond what's explicitly stated here. All other rights
        are reserved. You may not use the APIs for any other purpose without our prior written permission. To use and
        access the APIs, you must obtain an API Key through our registration process. You are required to: (i) not share
        your API Key with others, (ii) keep your API Key and login details secure, and (iii) use your API Key as your
        sole method of accessing the APIs. We reserve the right to deny or revoke API Key registration at our sole
        discretion.
      </StyledText>

      <StyledText mt="m">
        During the Term, you grant Vista Social the right to: (i) use, display, and operate Your Application and its
        content as part of the Vista Social Services; (ii) transfer content from Your Application to Third-Party
        Services, when instructed by a Vista Social User; and (iii) link to and direct Vista Social Users to Your
        Application.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        3. Use Restrictions
      </H2>

      <StyledText>Except as expressly authorized under these Terms, you and Your Users may not:</StyledText>

      <StyledText mt="m">You agree not to:</StyledText>

      <ul>
        <ListItem>
          a. Access any Data or publish content on behalf of an End User without first obtaining their consent;
        </ListItem>
        <ListItem>
          b. Request more Data from the APIs than is necessary for Your Application to function as intended, or request
          any Data beyond what the End User has permitted;
        </ListItem>
        <ListItem>
          c. Use Data for your own or another party’s purposes (including building or enhancing user profiles or
          identities);
        </ListItem>
        <ListItem>d. Copy, alter, or create derivative works from the APIs, whether in whole or part;</ListItem>
        <ListItem>
          e. Share, publish, transfer, publicly display, or otherwise provide the APIs or Data to any third party,
          except the End User to whom the Data belongs;
        </ListItem>
        <ListItem>
          f. Rent, sell, license, sublicense, assign, or otherwise distribute the APIs or Data to any third party
          (including data brokers, ad networks, or ad exchanges);
        </ListItem>
        <ListItem>
          g. Reverse engineer, decompile, disassemble, decode, adapt, or try to gain access to any software component of
          the APIs;
        </ListItem>
        <ListItem>h. Remove any proprietary notices from the APIs;</ListItem>
        <ListItem>
          i. Use the APIs in a way that infringes on any intellectual property rights, violates laws or regulations,
          including privacy laws;
        </ListItem>
        <ListItem>
          j. Use the APIs in a way that violates Vista Social’s Terms of Service, Usage Policy, or any terms governing
          Third-Party Services;
        </ListItem>
        <ListItem>
          k. Use the APIs to send unsolicited or unauthorized messages, or messages prohibited by law;
        </ListItem>
        <ListItem>
          l. Combine or integrate the APIs with any software, technology, or services not approved by Vista Social;
        </ListItem>
        <ListItem>
          m. Design or allow Your Application to bypass or interfere with Vista Social’s user prompts, consent flows,
          settings, alerts, or warnings;
        </ListItem>
        <ListItem>
          n. Hide or misrepresent your identity or that of Your Application when requesting API authorization;
        </ListItem>
        <ListItem>
          o. Access the APIs in a way that bypasses security measures, introduces vulnerabilities, or tests the system’s
          defenses;
        </ListItem>
        <ListItem>p. Use the APIs or Data in a way that exceeds usage limits or is considered abusive;</ListItem>
        <ListItem>q. Upload or transmit any Malicious Software through the APIs or Vista Social Services;</ListItem>
        <ListItem>
          r. Use Vista Social Services, APIs, or Data to imitate, compete with, or try to rebuild any features,
          functionality, or user experience of Vista Social Services;
        </ListItem>
        <ListItem>
          s. Monitor the APIs or Vista Social Services for competitive research, performance comparisons, or
          benchmarking;
        </ListItem>
        <ListItem>
          t. Access, store, display, or facilitate the transfer of content from Vista Social Services using scraping,
          crawling, spidering, or similar methods outside of the APIs ("Non-Official API Content"). This applies whether
          the content is obtained directly or indirectly (e.g., through a third party), whether it is used in Your
          Application or another resource/service, and whether or not the content is documented in the official API
          Documentation.
        </ListItem>
      </ul>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        4. Your Application and Your Responsibilities
      </H2>

      <StyledText mt="m">
        You agree to follow all terms outlined in these Terms, the Vista Social Terms of Service, the Privacy Policy,
        API Documentation, and all applicable laws, regulations, and any additional guidelines, standards, or
        requirements that Vista Social may introduce over time for use of the Vista Social Services and APIs. The APIs
        and API Documentation are meant for use by businesses and organizations only—not for personal or consumer use.
      </StyledText>
      <StyledText mt="m">
        Each API request from Your Application must be clearly identified as originating from Your Application, using
        the method specified by Vista Social (such as including a specific Header value), and this origin must be clear
        to Your Users. When you display Vista Social Posts, Message Data, or Content Objects outside of Vista Social
        Services, you must include a permalink for each item that has one available via the API. If there is no
        object-specific permalink, but a list-level permalink is provided, that link must be displayed to the user.
      </StyledText>
      <StyledText mt="m">
        Additionally, every Vista Social User must have an active subscription governed by the applicable Vista Social
        Terms of Service to use Vista Social Services. You must not encourage or enable Your Users or End Users to
        violate those Terms or interfere with their review or acceptance. You must also reasonably assist Vista Social
        in preventing or stopping any abuse or violations through Your Application. If you transmit any data to Vista
        Social Services on behalf of an End User, Vista Social will process it according to that user’s applicable Vista
        Social Terms of Service.
      </StyledText>
      <StyledText mt="m">
        You agree to monitor Your Application for any activity that breaks the law, breaches these Terms, or involves
        fraud or other harmful behavior. You must act quickly to restrict any users responsible for such activity. You
        also agree to provide a way for End Users to report abuse of Your Application.
      </StyledText>
      <StyledText mt="m">
        You are solely responsible for Your Application, Your Users, and all actions or omissions of Your Users and End
        Users. You are also responsible for posting any required privacy notices and obtaining all necessary End User
        consents under applicable laws.
      </StyledText>
      <StyledText mt="m">
        If Vista Social determines, in its sole discretion, that you’ve violated these Terms, engaged in fraud, or if
        your use of the APIs negatively impacts the performance, security, or stability of Vista Social Services or the
        APIs, we may take any action we deem necessary. This may include warnings, investigations, suspending or
        terminating your access, or pursuing other available remedies. Any resulting service interruptions will not be
        eligible for refunds or credits.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        5. Caching/Deletion
      </H2>

      <StyledText mt="m">
        You agree not to capture, copy, or store any Data or information related to or provided through the APIs
        (including hashed or transformed data), except as expressly allowed under these Terms. You must store all Data
        in a way that allows you to identify, separate, and selectively delete it. If you cache any Data, you must
        refresh that cache at least once every 24 hours. You may only retain Data for as long as is reasonably necessary
        to provide Your Application to Your Users. Unless otherwise required or restricted by law, you must delete a
        User’s Data within 30 days if: (i) you receive a deletion request from Vista Social, the User, or an applicable
        Third-Party Service; (ii) the User uninstalls or deauthorizes Your Application, or closes their account; or
        (iii) the Data is no longer needed to provide Your Application to that User. You also agree to promptly confirm
        with Vista Social that a deletion request has been fulfilled if they ask for verification.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        6. Beta features
      </H2>

      <StyledText mt="m">
        Subject to these Terms, Vista Social may offer you access to Beta Features solely for testing and evaluation
        purposes. You acknowledge and agree that Beta Features are provided “as is” and “as available,” without any
        warranties—express, implied, statutory, or otherwise. Vista Social may suspend, limit, or revoke your access to
        Beta Features at any time, at its sole discretion. Vista Social is not required to provide support for Beta
        Features and makes no guarantees that any issues or bugs will be resolved. Your use of Beta Features is entirely
        at your own risk and may be subject to additional conditions set by Vista Social. Under no circumstances will
        Vista Social be liable to you—including for any indemnification obligations—arising from your use or inability
        to use the Beta Features.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        7. Third-Party Services
      </H2>

      <StyledText mt="m">
        The Vista Social Services and APIs may include or interact with certain Third-Party Services. Use of Data from
        those services—by you, your Users, or End Users—is subject to the relevant Third-Party Service’s terms
        (including developer terms), and you may need to agree to additional conditions to access certain APIs. If a
        Third-Party Service requires Vista Social to remove specific Data, you agree to work with Vista Social to ensure
        the affected Data is deleted from your and your End Users’ systems within the given timeline and confirm
        completion upon request. You understand that Vista Social does not control the features or functionality of any
        Third-Party Services, which may change without notice. Vista Social is not responsible for any losses or changes
        caused by those Third-Party Services or any related changes to the APIs.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        8. Limits
      </H2>
      <StyledText mt="m">
        Your API usage may be subject to limits on access, calls, or usage, as detailed in these Terms, the
        Documentation, or otherwise communicated to you. Vista Social may impose or modify such limits at its discretion
        if your usage negatively affects the APIs, Vista Social Services, or their performance. Attempting to bypass or
        exceed these limits may result in temporary or permanent access restrictions.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        9. Data Security
      </H2>
      <StyledText mt="m">
        You must implement and maintain appropriate technical and organizational safeguards to protect Data, your API
        access, and the integrity and confidentiality of the Data. You are solely responsible for securing Data handled
        through Your Application and may only copy or store Data as permitted by these Terms.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        10. Security Incidents
      </H2>
      <StyledText mt="m">
        You must notify Vista Social at security@vistasocial.com within 24 hours of discovering: (i) any unauthorized
        API access or use, or (ii) any unauthorized access, use, or disclosure of Data (“Security Incident”). After
        discovering a Security Incident, you must disconnect from the APIs, investigate and resolve the issue, keep
        Vista Social informed, and cooperate as needed. You’re responsible for managing notifications and remedies
        required by law, and may not reference Vista Social publicly regarding a Security Incident without prior written
        approval.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        11. Data Privacy
      </H2>
      <StyledText mt="m">
        Both parties agree that when a Vista Social User uses the API to process Data, the User is the “data controller”
        or “business” under privacy laws, and Vista Social acts as the “data processor” or “service provider.” You agree
        to: (i) process Data lawfully and in line with all regulations and Third-Party Service terms; (ii) follow best
        practices for privacy and security; (iii) use Data only to fulfill obligations under these Terms or your
        agreement with the User; and (iv) never sell or share Data without the User’s explicit permission.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        12. Statistical and Usage Data
      </H2>
      <StyledText mt="m">
        Vista Social may collect and use data about how you use the APIs, Data, or Your Application (“Statistical and
        Usage Data”). Vista Social will not publicly identify you or your Users in connection with this data. All rights
        to such data remain with Vista Social.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        13. No Support; Updates
      </H2>
      <StyledText mt="m">
        These Terms do not guarantee any support for the APIs. Vista Social may update or modify the APIs at its
        discretion and may discontinue support for older versions. You’re responsible for updating Your Application as
        needed. Continued use of the APIs after an update means you accept the changes.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        14. Monitoring
      </H2>
      <StyledText mt="m">
        Vista Social may audit or monitor your API usage and related activities to verify compliance. You agree to
        cooperate and provide proof or access to Your Application as requested. You must not attempt to block or
        interfere with such monitoring.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        15. Resale
      </H2>
      <StyledText mt="m">
        You may not resell, redistribute, or create commitments on behalf of Vista Social. You also may not charge End
        Users specifically for access to Vista Social functionality or APIs, though you may charge for Your Application
        generally.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        16. Intellectual Property Ownership
      </H2>
      <StyledText mt="m">
        Vista Social owns all rights to the APIs and Vista Social Services. You own all rights to Your Application,
        excluding any Vista Social components. You agree to protect Vista Social’s APIs from misuse or unauthorized
        access and to notify Vista Social of any IP infringement. You also agree to assist Vista Social in protecting
        its IP rights.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        17. Feedback
      </H2>
      <StyledText mt="m">
        Any feedback you or your team provides to Vista Social regarding the APIs (including suggestions or feature
        ideas) will be considered non-confidential. You assign all rights in that feedback to Vista Social, which may
        use it freely without attribution or compensation.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        18. Confidentiality
      </H2>
      <StyledText mt="m">
        Vista Social may share confidential information with you to help fulfill these Terms. You agree to protect it
        using reasonable care and only use it as allowed here. Because misuse could cause harm to Vista Social, they may
        seek injunctive relief without posting a bond if confidentiality is breached.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        19. Disclaimer of Warranties
      </H2>
      <StyledText mt="m">
        The APIs, Documentation, and related content are provided “as is.” Vista Social disclaims all
        warranties—express, implied, statutory, or otherwise—including merchantability, fitness for a particular
        purpose, and non-infringement. Vista Social does not guarantee the APIs will meet your requirements, be
        uninterrupted or error-free, or work with your or third-party systems.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        20. Your Representations and Warranties
      </H2>
      <StyledText mt="m">
        You represent that Your Application: (i) complies with all applicable laws and third-party rights (including
        privacy and IP), and (ii) that you have all necessary permissions for any data you provide to Vista Social.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        21. Indemnification
      </H2>
      <StyledText mt="m">
        You agree to indemnify and defend Vista Social from any claims or losses arising from: (a) your or your Users’
        misuse of the APIs, (b) breach of these Terms, (c) Your Application or its use, and (d) any third-party rights
        violations. Vista Social will notify you of such claims and may choose to manage its own defense. You must not
        settle claims on Vista Social’s behalf without written approval.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        22. Limitation of Liability
      </H2>
      <StyledText mt="m">
        To the fullest extent allowed by law, Vista Social is not liable for indirect, special, or consequential damages
        (e.g., lost profits, data loss, system failure). Vista Social’s total liability is capped at $500 USD. Any claim
        must be brought within one year of the event. Some limitations may not apply if prohibited by law.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        23. Term and Termination
      </H2>
      <StyledText mt="m">
        These Terms begin when you first access the APIs and continue until terminated. Vista Social may suspend or
        terminate access at any time, with or without cause or notice. You may terminate by ceasing use and sending
        notice. Upon termination, all rights end, and you must delete all API materials. Vista Social may delete your
        account data and has no obligation for refunds. Any sections meant to survive termination will remain in effect.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        24. Non-Exclusivity
      </H2>
      <StyledText mt="m">
        You understand and agree that Vista Social may develop or offer products similar to Your Application.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        25. Export Regulations
      </H2>
      <StyledText mt="m">
        You agree not to export, re-export, or release the Vista Social Services or APIs to any restricted countries,
        individuals, or entities as defined by applicable U.S. export laws. You represent that you are not subject to
        such restrictions.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        26. Federal Government Use
      </H2>
      <StyledText mt="m">
        If you’re a U.S. federal government user, the APIs are considered “commercial items” under federal regulations
        and are licensed with only those rights described in these Terms.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        27. Modifications
      </H2>
      <StyledText mt="m">
        Vista Social may update these Terms by posting a revised version or notifying you through your account. If you
        disagree with any updates, your only option is to stop using the APIs and terminate these Terms. Continued use
        after changes take effect means you accept the updates.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        28. Governing Law
      </H2>
      <StyledText mt="m">
        These Terms are governed by the laws of the State of New York, without regard to conflicts of law. Any disputes
        must be resolved in courts located in Kings County, New York.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        29. Force Majeure
      </H2>
      <StyledText mt="m">
        Neither party will be liable for failure to perform obligations due to events beyond their control, provided
        they take reasonable steps to address the issue.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        30. Publicity and Branding
      </H2>
      <StyledText mt="m">
        You may not use Vista Social’s trademarks, name, or logos in promotions, naming, or in any way that implies
        endorsement without prior written permission. You also may not register any domains containing Vista Social
        Marks or challenge Vista Social’s ownership of them.
      </StyledText>

      <H2 mt="l" color={COLOR_CONSTANTS.DENIM} fontSize="xxl" fontWeight="bold">
        31. Miscellaneous
      </H2>
      <StyledText mt="m">
        These Terms are the complete agreement between you and Vista Social. The parties are independent contractors.
        There are no third-party beneficiaries. Notices to Vista Social must be sent by email and mail to its legal
        department; notices to you will be sent via email. You agree to receive electronic communications from Vista
        Social. If any part of these Terms is found unenforceable, the rest remains in effect. Vista Social’s failure to
        enforce any part of the Terms doesn’t waive its rights. You may not assign these Terms without written consent;
        Vista Social may assign them freely.
      </StyledText>
    </Box>

    <Footer />
  </App>
)

export default TOS
